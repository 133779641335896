<template>
  <div class="select-language-button-absolute">
    <v-btn
      @click="changeLanguage('en')"
      icon
      elevation="0"
      style="margin-bottom: 5px;"
      :style="(selectLanguage == 'en') ? { backgroundColor: '#00000040' } : {}"
    >
      <img
        src="@/assets/images/US.svg"
        width="20px"
        height="20px"
      >
    </v-btn>
    <v-btn
      @click="changeLanguage('sp')"
      icon
      elevation="0"
      :style="(selectLanguage == 'sp') ? { backgroundColor: '#00000040' } : {}"
    >
      <img
        src="@/assets/images/MX.svg"
        width="20px"
        height="20px"
      >
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SelectLanguageComponent",
  data() {
    return {
      direction: "bottom",
      fab: false,
      hover: true,
      transition: "slide-y-reverse-transition",
    };
  },
  methods: {
    changeLanguage: function (e) {
      this.$store.commit("toggle_language", e);
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
};
</script>

<style scope>
.selected-language {
  background-color: red !important;
}

.select-language-button {
  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: saturate(180%) blur(10px) !important;
}

.v-speed-dial--direction-top .v-speed-dial__list {
  width: fit-content !important;
  bottom: 60px !important;
}
/* This is for documentation purposes and will not be needed in your application */
.v-speed-dial {
  position: absolute !important;
  top: 10px !important;
  right: -32% !important;
  z-index: 5;
  /* left: 50%; */
}

.select-language-button-absolute {
  position: absolute !important;
  top: 10px !important;
  right: -32% !important;
  z-index: 5;
  display: grid;
}

.v-btn--floating {
  position: relative;
}

@media (max-width: 720px) {
  .v-speed-dial {
    right: 10px !important;
  }
}

@media (max-width: 960px) {
  .select-language-button-absolute {
    right: 10px !important;
  }
}
</style>